import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SignalRServiceService {
  private hubConnection!: signalR.HubConnection;

  startConnection() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.dromeImageUrl + '/VideoCallHub') // Replace with your hub URL
      .build();

    this.hubConnection
      .start()
      .then(() => console.log('SignalR connected'))
      .catch((err) => console.error('SignalR connection error:', err));
  }

  listenForCloseModal(callback: () => void) {
    this.hubConnection.on('CloseModal', callback);
  }

  joinRoom(roomId: string) {
    this.hubConnection.invoke('JoinRoom', roomId).catch((err) => console.error(err));
  }

  leaveRoom(roomId: string) {
    this.hubConnection.invoke('LeaveRoom', roomId).catch((err) => console.error(err));
  }
}
