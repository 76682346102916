<div class="net-issue" *ngIf="!isConnected"><span class="net-txt">
    <span style="color: red;margin-right: 5px;">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
        class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
        <path
          d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
      </svg>
    </span> Internet issue
  </span>
</div>
<div *ngIf="videourlbool=='true'" class="floatinfix">
  <app-floating-view [url]="videourl" [data]="data" (notifyParent)="closemodal($event)"></app-floating-view>
</div>
<div class="running-text"  [style]="'background :'+(SubscriptionStatus.split('.')[0]=='Your Subscription has been expired'?'red':'orange')+';color:'+(SubscriptionStatus.split('.')[0]=='Your Subscription has been expired'?'#fff !important':'#000 !important')+''" *ngIf="SubscriptionStatus&&roleId == '2'&&btIsClinic==0 || SubscriptionStatus&&roleId == '8'&&btIsClinic==0 " (click)="navigateTodoctorSubscrption()">
  <p [style]="'color:'+(SubscriptionStatus.split('.')[0]=='Your Subscription has been expired'?'#fff !important':'#000 !important')+''">{{SubscriptionStatus}}</p>
</div>
<div class="container-fluid top-header " >
  <div class="mobile-menu" (click)="sideMenuBar($event)">
    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
        </svg> -->
    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="58" viewBox="0 0 57 58">
      <g id="Group_2" data-name="Group 2" transform="translate(-425 -174)">
        <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="28.5" cy="29" rx="28.5" ry="29" transform="translate(425 174)"
          fill="#7087db" />
        <g id="Group_1" data-name="Group 1" transform="translate(435.292 187.458)">
          <rect id="Rectangle_1" data-name="Rectangle 1" width="36" height="6" rx="3"
            transform="translate(-0.292 -0.458)" fill="#fff" />
          <rect id="Rectangle_2" data-name="Rectangle 2" width="36" height="6" rx="3"
            transform="translate(-0.292 24.542)" fill="#fff" />
          <rect id="Rectangle_3" data-name="Rectangle 3" width="24" height="6" rx="3"
            transform="translate(-0.292 12.042)" fill="#fff" />
        </g>
      </g>
    </svg>
  </div>
  <div class="top-menu">
    <div class="lft-logo">
      <a href="https://drome.co.in/" alt=""><img [src]="urlLink2" class="logo" alt=""
          style="width: auto;height: 60px;"></a>
    </div>
    <!-- <span class="mobileMenu2" *ngIf="roleId == '1'" (click)="menuClick($event)">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 57 58">
                <g id="Group_2" data-name="Group 2" transform="translate(-425 -174)">
                    <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="28.5" cy="29" rx="28.5" ry="29"
                        transform="translate(425 174)" fill="#fff" />
                    <g id="Group_1" data-name="Group 1" transform="translate(435.292 187.458)">
                        <rect id="Rectangle_1" data-name="Rectangle 1" width="36" height="6" rx="3"
                            transform="translate(-0.292 -0.458)" />
                        <rect id="Rectangle_2" data-name="Rectangle 2" width="36" height="6" rx="3"
                            transform="translate(-0.292 24.542)" />
                        <rect id="Rectangle_3" data-name="Rectangle 3" width="24" height="6" rx="3"
                            transform="translate(11.708 12.042)" />
                    </g>
                </g>
            </svg>
        </span> -->

    <div class="right-menu" *ngIf="roleId == '1'">
      <ul [ngClass]="{'menuShow':menuShow}">
        <!-- <li><a (click)="redairect()" style="background: #31b0a4;">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
                            <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
                          </svg>
                    </span>
                    Home</a></li> -->
        <!-- <li><a (click)="openModalFirst(templateMedicine)">Order Medicines</a></li>
                <li><a (click)="openModalFirst(templateMedicine)">Order Investigations</a></li> -->
        <!-- <li><a (click)="openModalFirst(templateMedicine)">Ambulance Services</a></li>
                <li><a (click)="openModalFirst(templateMedicine)">Concierge Services</a></li> -->
      </ul>
    </div>
    <span class="flex"
      *ngIf="roleId == '1' || roleId == '2' || roleId == '3'|| roleId == '8'|| roleId == '11'|| roleId == '12'|| roleId == '13'|| roleId == '16'|| roleId == '17'">
      <span class="messageCount" routerLink="{{userRole}}/mailbox">Inbox: <b>{{this.count}}</b></span>
    </span>
    <div class="logoutbtn2" (click)="logout()">
      <span> 
        <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-box-arrow-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
            <path fill-rule="evenodd"
              d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
          </svg>
        </span>
      </span>
    </div>
  </div>
</div>

<section class="user-profile-section" >
  <!-- <mat-progress-bar mode='indeterminate' *ngIf="loaderService.isLoading | async"></mat-progress-bar> -->
  <div class="container-fluid ">
    <div class="user-box">
      <div class="row"  [ngClass]="{'margintop':SubscriptionStatus&&btIsClinic==0}">
        <div class="col-3" [ngClass]="{'sideBarClose' : toggleMenuProfile}">
          <div class="user-aside" [ngClass]="{'user-aside2' : roleId==2|| roleId==8&&btIsClinic==0,'forClinic':btIsClinic==1||roleId==20,'topSub':SubscriptionStatus&&btIsClinic==0}">
            <div class="close-profilemenu" (click)="closeProfilesMenu()">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x"
                viewBox="0 0 16 16">
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </div>
            <div class="user-head">
              <div class="user-head-box"
                [ngClass]="{'user-aside2' : roleId==2 || roleId==8,'forClinic':btIsClinic==1||roleId==20}">
                <div class="subscribedMember" [title]="SubscriptionDetails=='0'?'Subscription':SubscriptionDetail"
                  [ngClass]="{'subscribedMember2':SubscriptionDetails==0}" *ngIf="roleId==2&&btIsClinic==0||roleId==8"
                  (click)="navigateTodoctorSubscrption()"><span>{{SubscriptionDetails==0?' Off': ' On'}}</span></div>
                <div class="user-img">
                  <img [src]="urlLink" alt="">
                </div>
                <span class="user-img-edit">
                  <button type="button" (click)="ProfileFiles.click()" class="fileUpload-2" title="Edit Photo">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path
                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>
                  </button>
                  <span><input (click)="reset()" value="" type="file" (change)="selectFiles(templateImageCroper,$event)"
                      style="display: none;" #ProfileFiles> </span>
                </span>
                <div class="uploadBtn">
                  <div class="UploadImage" *ngIf="!uploadBtn">
                    <span class="button" (click)="openWebCam(templatewebcam)" title="Take Picture"
                      style="cursor: pointer;">
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                        class="bi bi-camera-fill" viewBox="0 0 16 16">
                        <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                        <path
                          d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
                      </svg>
                    </span>
                    <div class="webcam-model">
                      <ng-template #templatewebcam>

                        <div class="webcam-edit-model">
                          <div class="model-header">
                            <button type="button" class="close-btn pull-right" aria-label="Close"
                              (click)="modalRef.hide()">
                              <mat-icon>close</mat-icon>
                            </button>
                            <h1 mat-dialog-title>{{ModalTitle}}</h1>
                          </div>
                          <mat-divider></mat-divider>
                          <div mat-dialog-content>
                            <div class="webcam">
                              <webcam [height]="300" [width]="300" [trigger]="triggerObservable"
                                (imageCapture)="handleImage($event)" *ngIf="showWebcam">
                              </webcam>
                              <button class="button" (click)="takeSnapshot()" *ngIf="showWebcam">
                                <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-camera" viewBox="0 0 16 16">
                                    <path
                                      d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                                    <path
                                      d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                  </svg>
                                </span>
                                Capture
                              </button>
                              <button *ngIf="showWebcam" class="button" (click)="OffWebCame()">Switch Off
                                camera</button>
                              <span class="webcamOf-text" *ngIf="showText">Your camera is
                                off
                              </span>
                              <button *ngIf="showText" class="button" (click)="onWebCame()">Switch on
                                camera</button>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                  <div class="UploadImage" *ngIf="uploadBtn" title=" Upload Image" style="cursor: pointer;">
                    <span (click)="onUpload()" type="button" class="upload-btn">
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                        class="bi bi-cloud-arrow-up-fill" viewBox="0 0 16 16">
                        <path
                          d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z" />
                      </svg>
                    </span>
                  </div>
                </div>


              </div>
              <div>
                <p *ngIf="SubscriptionDetails!='0'" class="SubscriptionDetails">{{SubscriptionDetail}}</p>
              </div>
              <div class="user-name"
                [ngClass]="{'user-name-1' : roleId == '3' || roleId == '11' || roleId == '12' || roleId == '13' || roleId == '14' || roleId == '15' || roleId == '16' }">
                <span style="background: rgba( 0,208,255,0.6); padding-left: 3px;"
                  *ngIf="roleId == '3' || roleId == '11' || roleId == '12' || roleId == '13'|| roleId == '14' || roleId == '15' || roleId == '16'"
                  class="age-title"
                  [ngClass]="{'age-title-1' : roleId == '3' || roleId == '11' || roleId == '12' || roleId == '13'|| roleId == '14' || roleId == '15' || roleId == '16' }">
                  <!-- <spanng
                                        class="titleName"
                                        [ngClass]="{'titleName-1' : roleId == '3' || roleId == '11' || roleId == '12' || roleId == '13' }"></spanng> -->
                  <span class="name"
                    [ngClass]="{'name-1' : roleId == '3' || roleId == '11' || roleId == '12' || roleId == '13' || roleId == '14' || roleId == '15' || roleId == '16'}">
                    {{establishmentName}}
                  </span>
                </span>

                <span class="age-title"
                  [ngClass]="{'age-title-1' : roleId == '3' || roleId == '11' || roleId == '12' || roleId == '13' || roleId == '14' || roleId == '15' || roleId == '16'}"><span
                    class="titleName"
                    [ngClass]="{'titleName-1' : roleId == '3' || roleId == '11' || roleId == '12' || roleId == '13' || roleId == '14' || roleId == '15' || roleId == '16'}">Member
                    Id</span>
                  <span class="name"
                    [ngClass]="{'name-1' : roleId == '3' || roleId == '11' || roleId == '12' || roleId == '13' || roleId == '14' || roleId == '15' || roleId == '16'}">:
                    {{memberId}}
                  </span>
                </span>
                <span class="age-title"
                  [ngClass]="{'age-title-1' : roleId == '3' || roleId == '11' || roleId == '12' || roleId == '13' || roleId == '14' || roleId == '15' || roleId == '16'}"><span
                    class="titleName"
                    [ngClass]="{'titleName-1' : roleId == '3' || roleId == '11' || roleId == '12' || roleId == '13' || roleId == '14' || roleId == '15' || roleId == '16'}">Name
                  </span>
                  <span class="name"
                    [ngClass]="{'name-1' : roleId == '3' || roleId == '11' || roleId == '12' || roleId == '13' || roleId == '14' || roleId == '15' || roleId == '16'}">:
                    {{fullName}}
                  </span>
                </span>
                <span class="age-title"
                  [ngClass]="{'age-title-1' : roleId == '3' || roleId == '11' || roleId == '12' || roleId == '13' || roleId == '14' || roleId == '15' || roleId == '16'}"><span
                    class="titleName"
                    [ngClass]="{'titleName-1' : roleId == '3' || roleId == '11' || roleId == '12' || roleId == '13' || roleId == '14' || roleId == '15' || roleId == '16'}">Logged
                    in as</span>
                  <span class="name"
                    [ngClass]="{'name-1' : roleId == '3' || roleId == '11' || roleId == '12' || roleId == '13' || roleId == '14' || roleId == '15' || roleId == '16'}">:
                    {{roleName}}</span></span>
              </div>

            </div>
            <div class="user-aside-body">
              <ul class="user-profile-list"  >
                <!-- <li><a href="#">Book an Appointment</a></li> -->
                <li *ngFor="let item of pageDetails " [ngClass]="{'removeBorder' : item.nPageDependentId != null,'webHide':item.nPageId==178 }">
                  <div *ngIf="item.vPageName!=null && item.nPageDependentId==null"  >
                    <a style="display: flex; justify-content: space-between; align-items: center;"
                      routerLinkActive="list-item-active" routerLink="{{userRole}}/{{item.vPageName.toLowerCase()}}"
                      [queryParams]="{ title:item.vPageCaption }"
                      >
                      <!-- [queryParams]="{ title:item.vPageCaption }" (click)="closeMatSideBar($event)" -->
                      <span>{{item.vPageCaption}}</span>
                    </a>
                  </div>
                  <!-- <div *ngIf="item.vPageName==null && item.nPageDependentId==null" class="parentMenu"  (click)="toggleSubNav(item.nPageId,$event)">[ngClass]="{'list-item-active':openedSubMenuId==item.nPageId}" -->
                  <div *ngIf="item.vPageName==null && item.nPageDependentId==null" class="parentMenu"   (click)="toggleSubMenu(item.nPageId)">
                    <a style="display: flex; justify-content: space-between; align-items: center; " routerLinkActive="list-item-active" (click)="openModelsubscriptionoff(templateConformModel,item.nPageId)">
                      <!-- <a style="display: flex; justify-content: space-between; align-items: center; " routerLinkActive="list-item-active"  > -->
                      <span>{{item.vPageCaption}}</span>
                      <span class="arrowRotate" [ngClass]="{'rotateArrow' : openedSubMenuId==item.nPageId}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                        </svg>
                      </span>
                    </a>
                    <!-- <div  [ngClass]="{'bottom' : item.nPageId==44||item.nPageId==14 || item.nPageId==20|| item.nPageId==109||item.nPageId==77,'user-aside2':roleId==2 || roleId==8, 'subMenuWrapper2':submenu==true,'hidemenu':SubscriptionDetails==0&&BtSubscriptionFinal==0&&item.nPageId==78&&roleId==8||SubscriptionDetails==0&&BtSubscriptionFinal==0&&item.nPageId==78&&roleId==2,'forClinic':btIsClinic==1||roleId==20}"> -->
                    <div class="user-aside3">
                      <!-- <div class="subMenuWrapper" [ngClass]="{'bottom' : item.nPageId==44||item.nPageId==14 || item.nPageId==20|| item.nPageId==109||item.nPageId==77,'user-aside2':roleId==2 || roleId==8,'forClinic':btIsClinic==1||roleId==20, 'subMenuWrapper2':submenu==true}"> -->
                      <div class="subMenu" *ngFor="let page of pageDetails|pageFilter:item">

                        <!-- <span (click)='closeMatSideBar($event)' *ngIf="isSubMenuOpen(page.nPageDependentId)" [ngClass]="{'webHide':page .nPageId==187}"> -->
                        <span *ngIf="isSubMenuOpen(page.nPageDependentId)" (click)="toggleSubMenu(page.nPageDependentId)" [ngClass]="{'webHide':page .nPageId==187}">
                          <a routerLinkActive="list-item-active"
                            style="border-bottom: 0.5px solid rgba(222,220,217,0.7);"
                            routerLink="{{userRole}}/{{page.vPageName.toLowerCase()}}"
                            [queryParams]="{ title:page.vPageCaption }"
                            (click)="openModelsubscriptionoff2(templateConformModel2,page.nPageDependentId,page.nPageId)">
                            <!-- <a routerLinkActive="list-item-active" style="border-bottom: 0.5px solid rgba(222,220,217,0.7);" routerLink="{{userRole}}/{{page.vPageName.toLowerCase()}}" [queryParams]="{ title:page.vPageCaption }" > -->
                           - {{page.vPageCaption}}
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  
                </li>
                <!-- <li class="parentMenu" *ngFor="let item of pageDetailsFirsList " >
                                                <a style="display: flex; justify-content: space-between; align-items: center;"  routerLink="{{userRole}}/{{item.vPageName.toLowerCase()}}">
                                                <span>{{item.vPageCaption}}</span>
                                                <span><mat-icon>keyboard_arrow_right</mat-icon></span>
                                                </a>
                                                <div class="subMenu">
                                                    <div *ngFor="let page of pageDetails|pageFilter:item" >
                                                        <a mat-list-item routerLinkActive="list-item-active"
                                                            routerLink="{{userRole}}/{{page.vPageName.toLowerCase()}}"
                                                            (click)='closeMatSideBar()'>
                                                            {{page.vPageName}}
                                                        </a>
                                                    </div>
                                                </div>
                                                
                                            </li> -->


                <!-- <div class="show-more" *ngIf="showMoreLinks" [@SlideInOutAnimation]>
                                                <li *ngFor="let item of pageDetailsLastList "><a routerLinkActive="list-item-active"
                                                        routerLink="{{item.vPageName.toLowerCase()}}"
                                                        (click)="closeMatSideBar()">{{item.vPageCaption}}</a>
                                                    <div *ngFor="let page of pageDetails">
                                                        <a mat-list-item routerLinkActive="list-item-active"
                                                            routerLink="{{page.vPageName.toLowerCase()}}"
                                                            (click)='closeMatSideBar()'>
                                                            <mat-icon>keyboard_arrow_right</mat-icon>{{page.vPageName}}
                                                        </a>
                                                    </div>
                                                </li>

                                            </div> -->
                <!-- <li (click)="showMoreLink()" class="showMore"><a routerLinkActive="list-item-active">{{
                                                    !showMoreLinks ? 'More' : 'Less' }}<span
                                                        [ngClass]="{'rotate' : rotateToggle}"><svg
                                                            xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                            fill="currentColor" class="bi bi-arrow-down-circle" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd"
                                                                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                                        </svg></span></a></li> -->
              </ul>
            </div>
            <!-- <div class="setting" (click)="logout()" [ngClass]="{'setting2': roleId=='2' || roleId=='8'}">
              <span>
                <a style="font-weight: 500;">
                  Log Out
                  <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                        d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                      <path fill-rule="evenodd"
                        d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                    </svg>
                  </span>
                </a>
              </span>
            </div> -->
          </div>
        </div>
        <div class="col-9" [ngClass]="{'sideBarWidth' : toggleMenuProfile}" (click)="clickOutSide()">

          <router-outlet (activate)="onActivate($event)"></router-outlet>
        </div>
      </div>
    </div>

  </div>
</section>

<div class="country-model">
  <ng-template #templateMedicine>

    <div class="country-edit-model">
      <button type="button" class="close-btn pull-right" aria-label="Close" (click)="modalRef.hide()">
        <mat-icon>close</mat-icon>
      </button>
      <!-- <app-comming-soon></app-comming-soon> -->
    </div>
  </ng-template>
  <ng-template #templateImageCroper>
    <div class="country-edit-model">
      <div class="model-header">
        <button type="button" class="close-btn pull-right" aria-label="Close" (click)="modalRef.hide()">
          <mat-icon>close</mat-icon>
        </button>
        <h1 mat-dialog-title style="font-size: 18px;">Crop Image</h1>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div mat-dialog-content>
      <div class="crop-box" style="display: flex;justify-content: space-between; height: 50vh;">
        <image-cropper class="image-cropper" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
          [aspectRatio]="5/5" format="jpeg" (imageCropped)="imageCropped($event)" [resizeToWidth]="250"
          [imageQuality]=100></image-cropper>
        <div class="croped-image">
          <div class="image">
            <label>Preview</label>
            <img [src]="urlLink" style="width: 100%;" alt="">

          </div>
          <div class="croped">
            <button (click)="croped()">Crop</button>
          </div>
        </div>

      </div>


    </div>
  </ng-template>
  <div class="footer">
    <div class="f-left">
      <!-- <a href="https://drome.co.in/"><img [src]="urlLink2" class="logo"></a> -->
      <!-- <a href="https://drome.co.in/"><img src="../../../assets/images/beta_logo.png" class="logo"></a> -->
      <p>Drome Services Pvt. Ltd.</p>

    </div>
    <div class="f-right">
      <ul>
        <li><a href="https://drome.co.in/contact-us" target="_blank">Contact Us</a></li>
        <li><a href="https://drome.co.in/privacy-policy-2" target="_blank">Privacy Policy</a></li>
        <li><a href="https://drome.co.in/terms-and-conditions" target="_blank">Terms & Conditions</a></li>
      </ul>
    </div>
  </div>
  <!-- <input type="file" (change)="fileChangeEvent($event)" /> -->
  <ng-template #templateConformModel>
    <div class="country-edit-model">
      <div class="model-header">
        <button type="button" class="close-btn pull-right" aria-label="Close" (click)="modalRef.hide()">
          <mat-icon>close</mat-icon>
        </button>
        <h1 mat-dialog-title style="margin-bottom: 0;">ALERT !!</h1>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div mat-dialog-content>
      <p class="confirm-text" style="text-align: center;"><span (click)="navigateTodoctorSubscrption()"
          style="cursor: pointer;"><b>Subscribe </b></span> now to gain access.</p>
    </div>
    <mat-divider></mat-divider>
    <div class="confirm-btn mt-3">
      <button (click)="modalRef.hide()" type="button">Ok</button>

    </div>
  </ng-template>
  <ng-template #templateConformModel2>
    <div class="country-edit-model">
      <div class="model-header">
        <button type="button" class="close-btn pull-right" aria-label="Close" (click)="modalRef2.hide()">
          <mat-icon>close</mat-icon>
        </button>
        <h1 mat-dialog-title style="margin-bottom: 0;">ALERT !!</h1>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div mat-dialog-content>
      <p class="confirm-text" style="text-align: center;"><span (click)="navigateTodoctorSubscrption()"
          style="cursor: pointer;"><b>Subscribe </b></span> now to gain access.</p>
    </div>
    <mat-divider></mat-divider>
    <div class="confirm-btn mt-3">
      <button (click)="modalRef2.hide()" type="button">Ok</button>

    </div>
  </ng-template>
