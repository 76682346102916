import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/account/auth.services';
import { DataEncryptionDcryption } from 'src/app/core/services/dataEncryptionDcryption.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { PatientDetailDropDown } from 'src/app/models/patient-dropdown';
import { AdhocAppointmentService } from 'src/app/patient-dashboard/Clinic-Coodinator/cc-adhoc-appointments/adhoc-appointment-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-floating-view',
  templateUrl: './floating-view.component.html',
  styleUrls: ['./floating-view.component.scss']
})
export class FloatingViewComponent implements OnInit, OnDestroy, AfterViewInit {
  private isDragging = false;
  private isResizing = false;
  private isFullScreen = false;
  private currentResizeHandle: string | null = null;
  private startX = 0;
  private startY = 0;
  private startWidth = 0;
  private startHeight = 0;
  private startLeft = 0;
  private startTop = 0;
  private viewportWidth = 0;
  private viewportHeight = 0;
  @Input() url: any
  @Input() data: any
  modalRef: BsModalRef;
  addmemberForm :FormGroup
  loader=false
  constructor(
    private el: ElementRef,
    private sanitizer: DomSanitizer,
    private modalService: BsModalService,
    private ccAdhocAppointmentService: AdhocAppointmentService,
    private dataEncryptionDcryption: DataEncryptionDcryption,
    private notifier: NotificationService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService,
  ) { }
  safeUrl
  pageTitle
  externalUrl
  addmemberbtn=false
  Add1User
  Add2User
 Add1Usermas
  Add2Usermas
  ngOnInit(): void {
    this.addmemberForm = this.formBuilder.group({
      nPatientUserId: [null, [Validators.required]],
    });
    
   

    console.log("this.route.snapshot.queryParams.title",this.route.snapshot.queryParams.call)
  
    var unsafeUrl
     this.externalUrl = this.route.snapshot.queryParams?.call;
    let user = this.route.snapshot.queryParams?.us;
    if(this.externalUrl){
      if(user==this.storageService.userId){
        unsafeUrl = `${environment.videocallURL}/in/rest-call-handler/${this.route.snapshot.queryParams.call}`;
        this.addmemberbtn=false
      }
    }else{
      unsafeUrl = this.url;
      this.addmemberbtn=true
    }
    console.log('url',this.url)
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeUrl);
    console.log('safeUrl',this.safeUrl)
    this.Add1User=this.data?.Add1User
    this.Add2User=this.data?.Add2User
    this.Add1Usermas=this.data?.Add1User
    this.Add2Usermas=this.data?.Add2User
   

    this.viewportWidth = window.innerWidth;
    this.viewportHeight = window.innerHeight;
    window.addEventListener('resize', () => {
      this.viewportWidth = window.innerWidth;
      this.viewportHeight = window.innerHeight;
      if (!this.isFullScreen) {
        this.centerComponent();
      }
    });

    const element = this.el.nativeElement.querySelector('.floating-box');
    const resizeHandles = this.el.nativeElement.querySelectorAll('.resizer');
    const fullScreenButton = this.el.nativeElement.querySelector('.fullscreen-button');

    if (fullScreenButton) {
      fullScreenButton.addEventListener('click', () => this.toggleFullScreen());
    }

    element.addEventListener('mousedown', (event: MouseEvent) => this.onMouseDown(event));
    document.addEventListener('mouseup', () => this.onMouseUp());
    document.addEventListener('mousemove', (event: MouseEvent) => this.onMouseMove(event));

    resizeHandles.forEach(handle => {
      handle.addEventListener('mousedown', (event: MouseEvent) => this.onResizeMouseDown(event, handle));
    });

    document.addEventListener('mousemove', (event: MouseEvent) => this.onResizeMouseMove(event));
    document.addEventListener('mouseup', () => this.onResizeMouseUp());

    element.style.position = 'fixed';
    element.style.zIndex = '1059'; // High z-index to ensure it's on top
    window.addEventListener('message', (event) => {
      if (event.data.action === 'endCall') {
        // Logic to end the call, e.g., hang up or close the session
        console.log('Call ended');
      }
    });
    
  }
  
  ngAfterViewInit(): void {
    this.centerComponent();
  }
  get addmemberFormFormControls(): any {
    return this.addmemberForm.controls;
  }
 

  ngOnDestroy(): void {
    const element = this.el.nativeElement.querySelector('.floating-box');
    const resizeHandles = this.el.nativeElement.querySelectorAll('.resizer');
    const fullScreenButton = this.el.nativeElement.querySelector('.fullscreen-button');

    if (fullScreenButton) {
      fullScreenButton.removeEventListener('click', () => this.toggleFullScreen());
    }

    element.removeEventListener('mousedown', (event: MouseEvent) => this.onMouseDown(event));
    document.removeEventListener('mouseup', () => this.onMouseUp());
    document.removeEventListener('mousemove', (event: MouseEvent) => this.onMouseMove(event));

    resizeHandles.forEach(handle => {
      handle.removeEventListener('mousedown', (event: MouseEvent) => this.onResizeMouseDown(event, handle));
    });

    document.removeEventListener('mousemove', (event: MouseEvent) => this.onResizeMouseMove(event));
    document.removeEventListener('mouseup', () => this.onResizeMouseUp());
  }

  private centerComponent(): void {
    if (!this.isFullScreen) {
      const element = this.el.nativeElement.querySelector('.floating-box') as HTMLElement;
      const width = element.offsetWidth;
      const height = element.offsetHeight;

      const left = (this.viewportWidth - width) / 2;
      const top = (this.viewportHeight - height) / 2;

      this.constrainWithinBounds(element, left, top, width, height);
    }
  }

  private toggleFullScreen(): void {
    const element = this.el.nativeElement.querySelector('.floating-box') as HTMLElement;

    if (!this.isFullScreen) {
      // Save current size and position
      this.startWidth = element.offsetWidth;
      this.startHeight = element.offsetHeight;
      this.startLeft = element.offsetLeft;
      this.startTop = element.offsetTop;

      element.style.position = 'fixed';
      element.style.left = '0';
      element.style.top = '0';
      element.style.width = '100vw';
      element.style.height = '100vh';
      element.style.zIndex = '10000'; // Ensure it's on top

      this.isFullScreen = true;
    } else {
      element.style.position = 'absolute';
      element.style.left = `${this.startLeft}px`;
      element.style.top = `${this.startTop}px`;
      element.style.width = `${this.startWidth}px`;
      element.style.height = `${this.startHeight}px`;
      element.style.zIndex = '1059'; // Restore z-index

      this.isFullScreen = false;
    }

    this.centerComponent();
  }

  private constrainWithinBounds(element: HTMLElement, x: number, y: number, width: number, height: number): void {
    const viewportRect = {
      left: 0,
      top: 0,
      right: this.viewportWidth,
      bottom: this.viewportHeight
    };

    if (x < viewportRect.left) x = viewportRect.left;
    if (y < viewportRect.top) y = viewportRect.top;
    if (x + width > viewportRect.right) x = viewportRect.right - width;
    if (y + height > viewportRect.bottom) y = viewportRect.bottom - height;

    element.style.left = `${x}px`;
    element.style.top = `${y}px`;
    element.style.width = `${width}px`;
    element.style.height = `${height}px`;
  }

  onMouseDown(event: MouseEvent): void {
    if (event.target instanceof HTMLElement && !event.target.classList.contains('resizer')) {
      this.isDragging = true;
      this.startX = event.clientX;
      this.startY = event.clientY;
      const element = this.el.nativeElement.querySelector('.floating-box') as HTMLElement;
      this.startLeft = element.offsetLeft;
      this.startTop = element.offsetTop;
    }
  }

  onMouseUp(): void {
    this.isDragging = false;
    this.isResizing = false;
  }

  onMouseMove(event: MouseEvent): void {
    if (this.isDragging && !this.isFullScreen) {
      const element = this.el.nativeElement.querySelector('.floating-box') as HTMLElement;
      let newLeft = this.startLeft + (event.clientX - this.startX);
      let newTop = this.startTop + (event.clientY - this.startY);
      this.constrainWithinBounds(element, newLeft, newTop, element.offsetWidth, element.offsetHeight);
    }
  }

  onResizeMouseDown(event: MouseEvent, handle: HTMLElement): void {
    event.stopPropagation();
    this.isResizing = true;
    this.currentResizeHandle = handle.classList[1]; // Get resize handle type (e.g., 'bottom-right')
    this.startX = event.clientX;
    this.startY = event.clientY;
    const element = this.el.nativeElement.querySelector('.floating-box') as HTMLElement;
    this.startWidth = element.offsetWidth;
    this.startHeight = element.offsetHeight;
    this.startLeft = element.offsetLeft;
    this.startTop = element.offsetTop;
  }

  onResizeMouseMove(event: MouseEvent): void {
    if (this.isResizing && this.currentResizeHandle) {
      const element = this.el.nativeElement.querySelector('.floating-box') as HTMLElement;
      let dx = event.clientX - this.startX;
      let dy = event.clientY - this.startY;

      switch (this.currentResizeHandle) {
        case 'bottom-right':
          this.constrainWithinBounds(element, this.startLeft, this.startTop, this.startWidth + dx, this.startHeight + dy);
          break;
        case 'bottom-left':
          this.constrainWithinBounds(element, this.startLeft + dx, this.startTop, this.startWidth - dx, this.startHeight + dy);
          break;
        case 'top-right':
          this.constrainWithinBounds(element, this.startLeft, this.startTop + dy, this.startWidth + dx, this.startHeight - dy);
          break;
        case 'top-left':
          this.constrainWithinBounds(element, this.startLeft + dx, this.startTop + dy, this.startWidth - dx, this.startHeight - dy);
          break;
        case 'right':
          this.constrainWithinBounds(element, this.startLeft, this.startTop, this.startWidth + dx, this.startHeight);
          break;
        case 'bottom':
          this.constrainWithinBounds(element, this.startLeft, this.startTop, this.startWidth, this.startHeight + dy);
          break;
        case 'left':
          this.constrainWithinBounds(element, this.startLeft + dx, this.startTop, this.startWidth - dx, this.startHeight);
          break;
        case 'top':
          this.constrainWithinBounds(element, this.startLeft, this.startTop + dy, this.startWidth, this.startHeight - dy);
          break;
      }
    }
  }

  onResizeMouseUp(): void {
    this.isResizing = false;
  }
  fullmode=false
  togglefullmode(){
    this.fullmode=!this.fullmode
  }
  @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();
  async closemodal(){
    var startTime =await this.GetRoomIdDetails()
    console.log('startTime===',startTime)
    
    if(startTime[0].vStartTime&&startTime[0].vEndTime||!startTime[0].vStartTime&&!startTime[0].vEndTime){
      const data = 'false';
      this.notifyParent.emit(data);
      if(this.externalUrl){
        this.router.navigate(['/dashboard/pt/appointment'],{ queryParams: { title: "Appointments" }});
      }
    }else{
      this.notifier.showError("Call in progress.");
      return
    }
   
    
  }
  config: ModalOptions = {
    animated: true,
    backdrop: 'static',
    class: 'modal-dialog-centered modal-md',
  };
  AddMembers
 async openAddmember(template: TemplateRef<any>,btnType){
    this.loader=true
    var startTime =await this.GetRoomIdDetails()
    console.log('startTime===',startTime)
    
    if(startTime[0].vStartTime&&!startTime[0].vEndTime){
      console.log('startTime===inside',startTime)
      this.modalRef = this.modalService.show(template, this.config);
      this.AddMembers=btnType
      this.loader=false
    }else{
      if(!startTime[0].vStartTime){
        this.notifier.showError("Call not start yet");
        this.loader=false
      } 
      if(startTime[0].vStartTime&&startTime[0].vEndTime){
        this.notifier.showError("Call is end.");
        this.loader=false
      }
      return
    }

  }
  patientDetailDropDown: PatientDetailDropDown[] = [];
  bindPatientIdDropDown(searchText: string) {
    this.patientDetailDropDown = [];
    this.ccAdhocAppointmentService.getPatientDetailsLikeSearch(searchText).subscribe((response) => {
      let res = this.dataEncryptionDcryption.decryptString(response)
      console.log("res_p",res);
      
      this.patientDetailDropDown = res;
      if (this.patientDetailDropDown.length == 0) {
        this.noMatchFound = true;
      }
      // this.loaderService.isLoading.next(false);

    }, (error: HttpErrorResponse) => {
      this.notifier.showError(error.statusText);
    });
  }
  onPatientKey(event: any) {
    if (event.target.value.length == 0) {
      this.patientDetailDropDown = [];
      this.noMatchFound = false;
    }

    if (!!event.target.value) {
      if (event.target.value.length > 2) {
        this.bindPatientIdDropDown(event.target.value);
      }
    }
  }
  selectedPatientId
  PatientDetailForRoom
  memberId
  Add1MemberId
  Add2MemberId
  Add1MemberUrl
  Add2MemberUrl
  
  selectPatientId(patient, e) {
    this.addmemberForm.get('nPatientUserId')?.setValue(patient.PatientDetail);
    this.Add1MemberId=patient.nUserId
    this.Add2MemberId=patient.nUserId 
    this.selectPatientList = false;
    this.patientDetailDropDown = [];
    this.memberId=patient.PatientDetail.split('/ ')[1]
    this.createUserVideoCall(patient.PatientDetail.split('/ ')[1])
  }
  selectPatientList=false
noMatchFound=false
  clickPatientbox($event) {
    $event.stopPropagation();
    this.selectPatientList = true;
  }
  showSelectPatientList($event) {
    $event.stopPropagation();
    this.selectPatientList = true;
  }
  closePatientSearch() {
    this.selectPatientList = false;
    this.noMatchFound = false;
  }
  onFocusOut(event: any) {
    window.setTimeout(() => {
      this.closePatientSearch()
    }, 500)
  }
  onClick() {
    this.selectPatientList = false;
    this.noMatchFound = false;
  }
  async createUserVideoCall(status) {
    let alldata = await this.GetuserById(status)
    let data= JSON.parse(alldata)
    console.log('firstTwoCharsdata',data.error)
    if(data.error=="User was not found"){
    let firstTwoChars: string = status.substring(0, 2);
    console.log('firstTwoChars',firstTwoChars)
    let videocallrole
    if(firstTwoChars.toLocaleLowerCase()=="dt"){
      videocallrole="plus"
    }else{
       videocallrole="basic"
    }
    let data = {
      "_id": `${status}`,
      "_role": videocallrole,
      "_allow_camera": true,
      "_language": "en" 
    };
    this.authService.Createuser(data).subscribe((res_user: any) => {
      console.log("res_user", res_user);
        // alert("new user Created")
    }, (error: HttpErrorResponse) => {
        // alert(error)
    });
    }
    
  }
  GetuserById(vUserName): Promise<any>{
    return this.authService.Getuser(vUserName).toPromise();
  }
  Addparticipanttoaroom(): Promise<any>{
    let memberdata = {
        UserId: this.memberId,
      }
    return this.authService.Addparticipanttoaroom(this.data.vRoomId,memberdata).toPromise()

  }
  deleteUserId
  DeleteRoomParticipant(): Promise<any>{
    return this.authService.DeleteRoomParticipant(this.data.vRoomId,this.memberId).toPromise()
  }
  
  GetRoomIdDetails(): Promise<any>{
    let data = {
      vRoomId:this.data.vRoomId
    }
    return this.authService.GetRoomIdDetails(data).toPromise()
  }
  async EncounterDetails_VideoCall_Update(){
    this.loader=true
    let deleteuser=await this.DeleteRoomParticipant()
    console.log('deleteuser',deleteuser)
    let addmemberdata =  await this.Addparticipanttoaroom()
    let jsonaddmemberdata= JSON.parse(addmemberdata)
   
    let datas ={
      vRoomId:this.data.vRoomId,
      vAdd1URL:this.AddMembers=='Add 1 Member'?jsonaddmemberdata.participant.url:null,
      vAdd2URL:this.AddMembers=='Add 2 Member'?jsonaddmemberdata.participant.url:null,
      nAdd1UserId:this.AddMembers=='Add 1 Member'?this.Add1MemberId:null,
      nAdd2UserId:this.AddMembers=='Add 2 Member'?this.Add2MemberId:null,
    }
    this.authService.EncounterDetails_VideoCall_Update(datas).subscribe(res=>{
      if(res){
        console.log(res)
        if(res[0]?.vAdd1User){
          this.Add1User=res[0]?.vAdd1User
          this.Add1Usermas=res[0]?.vAdd1User
        }
        if(res[0]?.vAdd2User){
          this.Add2User=res[0]?.vAdd2User
          this.Add2Usermas=res[0]?.vAdd2User
        }
        this.notifier.showSuccess("Success")
        this.modalRef.hide()
        this.addmemberForm.reset()
        this.loader=false
      }
  })
  }
  text1="Add"
  text2="Add"
  tooglebtn1(type,deleteuser){
    if(type=="mem1can"){
      this.Add1User=this.Add1Usermas
    }else{
      this.Add1User=''
      this.deleteUserId=deleteuser.split('/ ')[1]
      this.text1="Update"
      this.addmemberForm.get('nPatientUserId')?.setValue(deleteuser);
      //this.Add1MemberId=patient.nUserId 
    } 
  } 
  tooglebtn2(type,deleteuser){
    if(type=="mem2can"){
      this.Add2User=this.Add2Usermas
    }else{
      this.Add2User=''
      this.deleteUserId=deleteuser.split('/ ')[1]
      this.text2="Update"
      this.addmemberForm.get('nPatientUserId')?.setValue(deleteuser);
      //this.Add2MemberId=patient.nUserId 
    }
  }
}