
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';
import { ModalModule } from 'ngx-bootstrap/modal';
import { WebcamModule } from 'ngx-webcam';
import { NotificationComponent } from './components/notification/notification.component';
import { NdOrganizationDetailsComponent } from './components/nd-organization-details/nd-organization-details.component';
import { CountryMasterComponent } from './components/country-master/country-master.component';
import { CityMasterComponent } from './components/city-master/city-master.component';
import { StateMasterComponent } from './components/state-master/state-master.component';
import { ConsultationchargesMasterComponent } from './components/consultationcharges-master/consultationcharges-master.component';
import { ShifttimingMasterComponent } from './components/shifttiming-master/shifttiming-master.component';
import { SpecializationMasterComponent } from './components/specialization-master/specialization-master.component';
import { PlantypeMasterComponent } from './components/plantype-master/plantype-master.component';
import { PlanMasterComponent } from './components/plan-master/plan-master.component';
import { PatientrelationshipMasterComponent } from './components/patientrelationship-master/patientrelationship-master.component';
import { ChiefComplaintMasterComponent } from './components/chief-complaint-master/chief-complaint-master.component';
import { DiagnosisMasterComponent } from './components/diagnosis-master/diagnosis-master.component';
import { DoseRegimenMasterComponent } from './components/dose-regimen-master/dose-regimen-master.component';
import { LabTestMasterComponent } from './components/lab-test-master/lab-test-master.component';
import { TherapyDurationMasterComponent } from './components/therapy-duration-master/therapy-duration-master.component';
import { ApproveUserMasterComponent } from './components/approve-user-master/approve-user-master.component';
import { UserDetailMasterComponent } from './components/user-detail-master/user-detail-master.component';
import { PatientDetailMasterComponent } from './components/patient-detail-master/patient-detail-master.component';
import { DoctorDetailMasterComponent } from './components/doctor-detail-master/doctor-detail-master.component';
import { NdMedicalRecordMasterComponent } from './components/nd-medical-record-master/nd-medical-record-master.component';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { APP_FORMATS } from '../core/shared/date-format';
import { BodyCheckupMasterComponent } from './components/body-checkup-master/body-checkup-master.component';
import { AdminViewProfileComponent } from './components/admin-view-profile/admin-view-profile.component';
import { SharedPrescriptionPrintComponent } from './components/prescription-print/prescription-print.component';
import { CommonChangePasswordComponent } from './components/common-change-password/common-change-password.component';
import { CommonChangeMobileNoComponent } from './components/common-change-mobile-no/common-change-mobile-no.component';
import { CommonChangeEmailComponent } from './components/common-change-email/common-change-email.component';
import { PrintInvoiceComponent } from './components/print-invoice/print-invoice.component';

import { PatientSignupNdComponent } from './components/patient-signup-nd/patient-signup-nd.component';
import { NdADHOCAppointmentComponent } from './components/nd-adhoc-appointment/nd-adhoc-appointment.component';
import { SystemicExaminationMasterComponent } from './components/systemic-examination-master/systemic-examination-master.component';
import { OtherInstructionsMasterComponent } from './components/other-instructions-master/other-instructions-master.component';
import { AddhealthparametersComponent } from './components/addhealthparameters/addhealthparameters.component';

import { UpdatePaymentDetailsComponent } from './components/update-payment-details/update-payment-details.component';
//import { //NgxWatermarkModule } from 'ngx-watermark';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { CostPerCreditPointsComponent } from './components/cost-per-credit-points/cost-per-credit-points.component';
import { SeniorAgeLimitCreditMasterComponent } from './components/senior-age-limit-credit-master/senior-age-limit-credit-master.component';
import { HealthCreditsMasterComponent } from './components/health-credits-master/health-credits-master.component';
import { CreditRuleMasterComponent } from './components/credit-rule-master/credit-rule-master.component';
import { CreditLimitForGiftCallsComponent } from './components/credit-limit-for-gift-calls/credit-limit-for-gift-calls.component';
import { VcLinkTimeLimitComponent } from './components/vc-link-time-limit/vc-link-time-limit.component';
import { PrintCreditReceiptComponent } from './components/print-credit-receipt/print-credit-receipt.component';

import { CreditUsageReportComponent } from './components/credit-usage-report/credit-usage-report.component';
import { EnrolledPlanDetailssaComponent } from './components/enrolled-plan-detailssa/enrolled-plan-detailssa.component';
import { BuyCreditsaComponent } from './components/buy-creditsa/buy-creditsa.component';
import { AppointmentDetailssaComponent } from './components/appointment-detailssa/appointment-detailssa.component';
import { LeaveDetailsMasterComponent } from './components/leave-details-master/leave-details-master.component';

import { PrintPrescriptionWithoutLogoComponent } from './components/print-prescription-without-logo/print-prescription-without-logo.component';
import { OrgMobnoAndEmailIdComponent } from './components/org-mobno-and-email-id/org-mobno-and-email-id.component';
import { FindMemberDetailsComponent } from './components/find-member-details/find-member-details.component';
import { ApproveRejectLicenseCopyComponent } from './components/approve-reject-license-copy/approve-reject-license-copy.component';
import { ChatEngineComponent } from './components/chat-engine/chat-engine.component';
import { ScrollOnMouseenterComponent } from './components/chat-engine/scroll-on-mouseenter.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PrintPharmacyPrescriptionComponent } from './components/print-pharmacy-prescription/print-pharmacy-prescription.component';
import { PrintLabPrescriptionComponent } from './components/print-lab-prescription/print-lab-prescription.component';
import { DoctorInstituteLinkageReportMasterComponent } from './components/doctor-institute-linkage-report-master/doctor-institute-linkage-report-master.component';
import { EstablishmentHighlightsMasterComponent } from './components/establishment-highlights-master/establishment-highlights-master.component';
import { ReferredtocasesComponent } from './components/referredtocases/referredtocases.component';
import { ReferredfromcasesComponent } from './components/referredfromcases/referredfromcases.component';
import { OffreImageUploadComponent } from './components/offre-image-upload/offre-image-upload.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { PrescriptionPreviewComponent } from './components/prescription-preview/prescription-preview.component';
import { MedicinemanufacturermasterComponent } from './components/medicinemanufacturermaster/medicinemanufacturermaster.component';
import { MedicinecategorymasterComponent } from './components/medicinecategorymaster/medicinecategorymaster.component';
import { MedicinemasterComponent } from './components/medicinemaster/medicinemaster.component';
import { SubscriptionmasterComponent } from './components/subscriptionmaster/subscriptionmaster.component';
import { ApprovedoctorssubscriptionsComponent } from './components/approvedoctorssubscriptions/approvedoctorssubscriptions.component';
import { DoctorssubscriptionlistComponent } from './components/doctorssubscriptionlist/doctorssubscriptionlist.component';
import { AmbulancesdetailsComponent } from './components/ambulancesdetails/ambulancesdetails.component';
import { AmbulancedriversdetailsComponent } from './components/ambulancedriversdetails/ambulancedriversdetails.component';
import { DicomviewerComponent } from './components/dicomviewer/dicomviewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MedicalCertificatepreviewComponent } from './components/medical-certificatepreview/medical-certificatepreview.component';
import { PreMembersignupComponent } from './components/pre-membersignup/pre-membersignup.component';
import { PatientcreatabhaComponent } from './components/patientcreatabha/patientcreatabha.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MaskInputDirective } from '../core/services/mask-input.directive';
import { AbhacarddetailsComponent } from './components/abhacarddetails/abhacarddetails.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { FloatingViewComponent } from './components/floating-view/floating-view.component';

import { LeaveReportComponent } from './components/leave-report/leave-report.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { CreditPointsRedeemPercentageComponent } from './components/credit-points-redeem-percentage/credit-points-redeem-percentage.component';
import { CreditPointRedeemComponent } from './components/credit-point-redeem/credit-point-redeem.component';
import { AppointmentsStatisticsComponent } from './components/appointments-statistics/appointments-statistics.component';

import { MedicineMealComponent } from './components/medicine-meal/medicine-meal.component';






@NgModule({
    declarations: [
        AutofocusDirective,
        // NdPatientDetialComponent,
        NotificationComponent,
        NdOrganizationDetailsComponent,
        CountryMasterComponent,
        CityMasterComponent,
        StateMasterComponent,
        ConsultationchargesMasterComponent,
        ShifttimingMasterComponent,
        SpecializationMasterComponent,
        PlantypeMasterComponent,
        PlanMasterComponent,
        PatientrelationshipMasterComponent,
        ChiefComplaintMasterComponent,
        DiagnosisMasterComponent,
        DoseRegimenMasterComponent,
        LabTestMasterComponent,
        TherapyDurationMasterComponent,
        ApproveUserMasterComponent,

        UserDetailMasterComponent,
        PatientDetailMasterComponent,
        DoctorDetailMasterComponent,
        NdMedicalRecordMasterComponent,
        BodyCheckupMasterComponent,
        AdminViewProfileComponent,
        SharedPrescriptionPrintComponent,
        CommonChangePasswordComponent,
        CommonChangeMobileNoComponent,
        CommonChangeEmailComponent,
        PrintInvoiceComponent,
        PatientSignupNdComponent,
        NdADHOCAppointmentComponent,
        SystemicExaminationMasterComponent,
        OtherInstructionsMasterComponent,
        AddhealthparametersComponent,
        UpdatePaymentDetailsComponent,
        CostPerCreditPointsComponent,
        SeniorAgeLimitCreditMasterComponent,
        HealthCreditsMasterComponent,
        CreditRuleMasterComponent,
        CreditLimitForGiftCallsComponent,
        VcLinkTimeLimitComponent,
        PrintCreditReceiptComponent,
        CreditUsageReportComponent,
        EnrolledPlanDetailssaComponent,
        BuyCreditsaComponent,
        AppointmentDetailssaComponent,
        LeaveDetailsMasterComponent,
        LeaveReportComponent,
        PrintPrescriptionWithoutLogoComponent,
        OrgMobnoAndEmailIdComponent,
        FindMemberDetailsComponent,
        ApproveRejectLicenseCopyComponent,
        ChatEngineComponent,
        ScrollOnMouseenterComponent,
        PrintPharmacyPrescriptionComponent,
        PrintLabPrescriptionComponent,
        DoctorInstituteLinkageReportMasterComponent,
        EstablishmentHighlightsMasterComponent,
        ReferredtocasesComponent,
        ReferredfromcasesComponent,
        OffreImageUploadComponent,
        PrescriptionPreviewComponent,
        MedicinemanufacturermasterComponent,
        MedicinecategorymasterComponent,
        MedicinemasterComponent,
        SubscriptionmasterComponent,
        ApprovedoctorssubscriptionsComponent,
        DoctorssubscriptionlistComponent,
        AmbulancesdetailsComponent,
        AmbulancedriversdetailsComponent,
        DicomviewerComponent,
        MedicalCertificatepreviewComponent,
        PreMembersignupComponent,
        PatientcreatabhaComponent,
        MaskInputDirective,
        AbhacarddetailsComponent,
        FloatingViewComponent,
        CreditPointsRedeemPercentageComponent,
        CreditPointRedeemComponent,
        AppointmentsStatisticsComponent,
        MedicineMealComponent

    ],
    imports: [
        CommonModule,
        MatListModule,
        MatSidenavModule,
        MatToolbarModule,
        MatMenuModule,
        MatIconModule,
        MatDividerModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        //MatSelectCountryModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        ModalModule.forRoot(),
        DataTablesModule,
        RouterModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatSelectModule,
        WebcamModule,
        MatExpansionModule,
        MatDatepickerModule,
        NgxMaterialTimepickerModule,
        //NgxWatermarkModule,
        ImageCropperModule,
        // CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
        NgxMaskModule.forRoot(),
        NgxExtendedPdfViewerModule,
        MatTabsModule,
        NgOtpInputModule,
    
        
    ],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: APP_FORMATS },
    ],
    exports: [
        AutofocusDirective,
        // NdPatientDetialComponent,
        NotificationComponent,
        NdOrganizationDetailsComponent,
        CountryMasterComponent,
        CityMasterComponent,
        StateMasterComponent,
        ConsultationchargesMasterComponent,
        ShifttimingMasterComponent,
        SpecializationMasterComponent,
        PlantypeMasterComponent,
        PlanMasterComponent,
        PatientrelationshipMasterComponent,
        ChiefComplaintMasterComponent,
        DiagnosisMasterComponent,
        DoseRegimenMasterComponent,
        LabTestMasterComponent,
        TherapyDurationMasterComponent,
        ApproveUserMasterComponent,
        UserDetailMasterComponent,
        PatientDetailMasterComponent,
        DoctorDetailMasterComponent,
        NdMedicalRecordMasterComponent,
        BodyCheckupMasterComponent,
        AdminViewProfileComponent,
        SharedPrescriptionPrintComponent,
        CommonChangePasswordComponent,
        CommonChangeMobileNoComponent,
        CommonChangeEmailComponent,
        PrintInvoiceComponent,
        PatientSignupNdComponent,
        NdADHOCAppointmentComponent,
        SystemicExaminationMasterComponent,
        OtherInstructionsMasterComponent,
        AddhealthparametersComponent,
        UpdatePaymentDetailsComponent,

        CostPerCreditPointsComponent,

        SeniorAgeLimitCreditMasterComponent,

        HealthCreditsMasterComponent,
        CreditRuleMasterComponent,
        CreditLimitForGiftCallsComponent,
        VcLinkTimeLimitComponent,
        PrintCreditReceiptComponent,
        CreditUsageReportComponent,
        EnrolledPlanDetailssaComponent,
        BuyCreditsaComponent,
        AppointmentDetailssaComponent,
        LeaveDetailsMasterComponent,
        LeaveReportComponent,
        PrintPrescriptionWithoutLogoComponent,
        OrgMobnoAndEmailIdComponent,
        FindMemberDetailsComponent,
        ApproveRejectLicenseCopyComponent,
        ChatEngineComponent,
        ScrollOnMouseenterComponent,
        PrintPharmacyPrescriptionComponent,
        PrintLabPrescriptionComponent,
        DoctorInstituteLinkageReportMasterComponent,
        EstablishmentHighlightsMasterComponent,
        ReferredtocasesComponent,
        ReferredfromcasesComponent,
        OffreImageUploadComponent,
        PrescriptionPreviewComponent,
        MedicinemanufacturermasterComponent,
        MedicinecategorymasterComponent,
        MedicinemasterComponent,
        SubscriptionmasterComponent,
        ApprovedoctorssubscriptionsComponent,
        MedicalCertificatepreviewComponent,
        PatientcreatabhaComponent,
        FloatingViewComponent,
        MedicineMealComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class SharedModule { }
